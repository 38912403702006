import {
  Divider,
  Stack,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { Strings } from '@app/constants';
import { Header } from '@app/sections/header';
import { FormProvider } from '@app/components';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  onSelectDate: (slotDate: any) => void;
  onSubmit: (slotId: number | null) => void;
  slots?: any;
  activeSlot: any;
  changeDeliverySlotLoading: boolean;
}
interface ISlot {
  slotType: string;
  duration: number;
  deliveryStartTime: string;
  deliveryEndTime: string;
  id: number;
  name: string;
}

const AddDeliverySlot = ({
  open,
  onClose,
  onSelectDate,
  onSubmit,
  slots,
  activeSlot,
  changeDeliverySlotLoading,
}: Props) => {
  const methods = useForm({});
  const [selectedSlot, setSelectedSlot] = useState<number | null>(
    activeSlot ? activeSlot.slotId : null
  );

  useEffect(() => {
    if (activeSlot?.deliveryDate) {
      onSelectDate(
        dayjs(activeSlot?.deliveryDate)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
      );
    }
  }, []);

  const handleSlotClick = (id: number) => {
    setSelectedSlot(id);
  };

  const handleSubmit = () => {
    onSubmit(selectedSlot);
  };

  return (
    <>
      <FormProvider methods={methods}>
        <Box sx={{ flexGrow: 1 }}>
          <Dialog open={open} fullWidth>
            <Header
              variant="dialog"
              title={Strings.pageTitle.selectDeliveryDateTime}
              onClose={onClose}
            />
            <Divider />
            <DialogContent>
              <Stack spacing={2} my={2} mb={5}>
                <Grid item xs={12}>
                  <DatePicker
                    label="Slot date"
                    sx={{ width: '100%' }}
                    defaultValue={dayjs(activeSlot?.deliveryDate).utc()}
                    onChange={(newValue: any) => {
                      onSelectDate(dayjs(newValue).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'));
                    }}
                  />
                </Grid>
                <Stack direction="row" spacing={0.5}>
                  <Stack flexDirection={'row'} flexWrap={'wrap'} justifyContent={'center'}>
                    {slots?.map((slot: ISlot) => (
                      <Button
                        key={slot.id}
                        color={selectedSlot === slot.id ? 'primary' : 'inherit'}
                        variant={selectedSlot === slot.id ? 'contained' : 'outlined'}
                        onClick={() => {
                          handleSlotClick(slot.id);
                        }}
                        sx={{
                          borderRadius: 1,
                          p: 1,
                          m: 0.4,
                          boxShadow: (theme) => theme.customShadows.z4,
                          textTransform: 'initial',
                        }}
                      >
                        <Typography variant="body2">{`${slot.name}`}</Typography>
                      </Button>
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button variant="outlined" color="inherit" onClick={onClose}>
                {Strings.button.cancel}
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={changeDeliverySlotLoading}
                onClick={methods.handleSubmit(handleSubmit)}
              >
                {Strings.button.save}
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Box>
      </FormProvider>
    </>
  );
};

export default AddDeliverySlot;
