export const getParentCategory = '/category/get-parent-category';
export const getCategory = '/category/getAll';
export const getCategoryDetail = '/category/';
export const addCategory = '/category/add-category';
export const addParentCategory = '/category/add-parent-category';
export const updateCategory = '/category/';
export const deleteCategory = '/category/';
export const updateTopCategory = '/category/top-category';
export const updateCategoryStatus = '/category/update-status/';
export const comingSoonCategoryStatus = '/category/coming-soon';
