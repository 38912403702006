import { useState } from 'react';
import { Stack, InputAdornment, IconButton } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';

import { useRequestState } from '@app/hooks';
import { FormProvider, Iconify, RHFTextField } from '@app/components';
import { Strings } from '@app/constants';
import { login } from '../slice';
import type { ILoginSchema } from '../types';
import { loginSchema } from '../validators';
import { selectLoginState } from '../selectors';

// --------------------------------------------

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();

  const onClickEyeIcon = () => {
    setShowPassword(!showPassword);
  };

  const methods = useForm<ILoginSchema>({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = (form: ILoginSchema) => {
    dispatch(login(form));
    console.log(form);
  };

  const { loading } = useRequestState({
    stateSelector: selectLoginState,
    errorShown: true,
    successMessageShown: true,
  });

  return (
    <FormProvider methods={methods} onSubmit={methods.handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <RHFTextField
          data-testId="login.email"
          name="email"
          type="email"
          label={Strings.field.email}
          fullWidth
        />
        <RHFTextField
          data-testId="login.password"
          name="password"
          label={Strings.field.password}
          fullWidth
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={onClickEyeIcon}>
                  {showPassword ? <Iconify icon="mdi:eye" /> : <Iconify icon="mdi:eye-off" />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <LoadingButton
          data-testId="login.submit"
          size="large"
          variant="contained"
          color="secondary"
          fullWidth
          type="submit"
          loading={loading}
        >
          {Strings.button.login}
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
};

export { LoginForm };
