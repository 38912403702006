import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

import { TableCellCustom } from '@app/components/table';
import { TruncatedTypography } from '@app/components/typography';
import { Strings } from '@app/constants';
import { calculateTotalDiscount, calculateTotalPrice, isActiveSlot } from '@app/utils';
import { IDeliverySlot } from '../types';

interface Props {
  row?: any;
  selected: boolean;
  onSelectRow: VoidFunction;
}

const OrderRow = (props: Props) => {
  const { row, onSelectRow } = props;

  const totalPrice: any = calculateTotalPrice(row?.orderProducts);
  const totalDiscount: any = calculateTotalDiscount(row?.orderProducts);
  const activeSlot: IDeliverySlot = isActiveSlot(row?.orderSlots);

  const Total = totalPrice - totalDiscount;

  const discountPercents = (totalDiscount / totalPrice) * 100;

  const fullAddress =
    `${String(row?.customerAddress?.city)},${String(row?.customerAddress?.country)}` ?? 'NA';

  const slotDetail =
    activeSlot?.slotType === 'normal'
      ? `${dayjs(`2022-01-01 ${activeSlot?.deliveryStartTime}`).format('hh:mm A')} - ${dayjs(
          `2022-01-01 ${activeSlot?.deliveryEndTime}`
        ).format('hh:mm A')}`
      : `Express (${activeSlot?.duration} min)`;

  return (
    <>
      <TableCellCustom onClick={onSelectRow}>
        <TruncatedTypography text={row?.id ?? 'NA'} />
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} fullWidth>
        <Stack width="160px">
          <Typography variant="body2">{row?.orderId}</Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow} fullWidth>
        <Stack flexDirection="row">
          <Typography variant="body2" pr={2}>{`${Strings.field.name}:`}</Typography>
          <TruncatedTypography text={row?.customerAddress?.name ?? 'NA'} />
        </Stack>
        <Stack flexDirection="row">
          <Typography variant="body2" pr={1}>{`${Strings.field.phone}:`}</Typography>
          <TruncatedTypography text={row?.customerAddress?.mobileNumber ?? 'NA'} />
        </Stack>
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow}>
        <TruncatedTypography text={fullAddress} />
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <TruncatedTypography text={row?.orderProducts[0]?.sku?.skuId} />
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center" fullWidth>
        <Stack width="150px">
          <TruncatedTypography
            text={`${(row?.orderProducts[0]?.sku?.name as string) ?? 'Sku Name'},`}
          />
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Stack width={'100px'}>
          <TruncatedTypography variant="body2" text={row?.orderProducts[0]?.vendorAddress?.name} />
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">{row?.orderProducts?.length}</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="left">
        <Stack width="200px">
          <Typography variant="body2">{row?.paymentMode}</Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Stack width="70px">
          <Typography variant="body2">AED {totalPrice}</Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">{discountPercents?.toFixed(2)}%</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Stack width="70px">
          <Typography variant="body2">AED {totalDiscount}</Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Stack width="70px">
          <Typography variant="body2">AED {Total?.toFixed(2)}</Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom flex align="center" onClick={onSelectRow}>
        <Stack width="80px">
          <Typography variant="body2">
            {activeSlot?.deliveryDate ? dayjs(activeSlot?.deliveryDate).format('YYYY-MM-DD') : 'NA'}
          </Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Stack width="80px">
          <Typography variant="body2">AED {activeSlot?.deliveryCharge}</Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Stack width="80px">
          <Typography variant="body2">{slotDetail}</Typography>
        </Stack>
      </TableCellCustom>
    </>
  );
};

export { OrderRow };
